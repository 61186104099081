@import "../../shared/assets/fonts/cofo/stylesheet.css";
@import "../../shared/assets/fonts/gilroy/stylesheet.css";

body {
    margin: 0;
    padding: 0;
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Gilroy', serif;

    *::-webkit-scrollbar {
        width: 6px;
        background: #fff;
        border-radius: 6px;
    }

    *::-webkit-scrollbar-thumb {
        background: #dcdcde;
        border-radius: 6px;
    }

    scrollbar-track-color: #dcdcde;
}
