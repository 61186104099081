@font-face {
    font-family: 'CoFo';
    font-weight: 400;
    font-display: swap;
    font-style: normal;
    src: local('CoFoSans Regular'), local('CoFoSans-Regular'), url(./CoFoSans-Regular.woff2) format('woff2');
}

@font-face {
    font-family: 'CoFo';
    font-weight: 500;
    font-display: swap;
    font-style: normal;
    src: local('CoFoSans Medium'), local('CoFoSans-Medium'), url(./CoFoSans-Medium.woff2) format('woff2');
}

@font-face {
    font-family: 'CoFo';
    font-weight: 700;
    font-display: swap;
    font-style: normal;
    src: local('CoFoSans Bold'), local('CoFoSans-Bold'), url(./CoFoSans-Bold.woff2) format('woff2');
}

@font-face {
    font-family: 'CoFo';
    font-weight: 900;
    font-display: swap;
    font-style: normal;
    src: local('CoFoSans Black'), local('CoFoSans-Black'), url(./CoFoSans-Black.woff2) format('woff2');
}
